import {
  Menu,
  MenuItem,
  styled,
  Typography,
} from "@mui/material";

export const StyledMenu = styled(Menu)({
  "& .MuiMenu-paper": {
    backgroundColor: "#EEEDF7",

    borderRadius: "0.5rem",
    boxShadow:
      "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
    padding: "0.5rem 0",
    // marginTop: "0.7%",
  },
});

export const StyledMenuItem = styled(MenuItem)({
  padding: "0.5rem 0.75rem",
  fontSize: "1rem",
  fontWeight: 400,
  lineHeight: "1.5rem",
  fontFamily: "Roboto",
  minWidth: "6.5rem",

  "&:hover": {
    backgroundColor: "#DDDCE6",
  },
  "&.Mui-selected": {
    backgroundColor: "#E2E1EC",
  },
  "&.Mui-selected:hover": {
    backgroundColor: "#DDDCE6",
  },
});
