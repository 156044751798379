import React from "react";
import PoolsPage from "./PoolsPage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PoolsPageContextProvider } from "./context/PoolsPageContextProvider";

const PoolsConfigurationPage = () => {
    const queryClient = new QueryClient({
        defaultOptions: {
            mutations: {
                // Default options for all mutations
                retry: 1, // Number of retry attempts
                onSuccess: (data) => {
                    console.log("Mutation succeeded:", data);
                },
                onError: (error) => {
                    console.error("Mutation failed:", error);
                },
            },
        },
    });
    return (
        <PoolsPageContextProvider>
            <QueryClientProvider client={queryClient}>
                <PoolsPage />
            </QueryClientProvider>
        </PoolsPageContextProvider>
    );
};

export default PoolsConfigurationPage;
