import IconButton from "@mui/material/IconButton";
import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { WhiteListURLHeader } from "../../../Components/WhiteListUrlsHeader/WhiteListUrlsHeader";
import { BodyLarge } from "../../../../components/StyledComponents/Typography/Typography.tsx";
import { StyledIconButton } from "../../../../components/StyledComponents/Buttons/AuradineButtons.js";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { PoolCardMenu } from "../../../Components/PoolCard/PoolCard.js";

const UrlWhiteLists = () => {
  const URLs = [
    "https://www.google.com",
    "https://www.facebook.com",
    "https://www.twitter.com",
    "https://www.linkedin.com",
    "https://www.instagram.com",
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      className={"table-container"}
      display={"flex"}
      flexDirection={"column"}
      padding={"0 0.75rem 0 0.75rem"}
      height={"auto"}
      overflow={"auto"}
      minWidth={"0"}
      gap={4}
      flex={3}
      flexShrink={1}
    >
      <WhiteListURLHeader data={URLs} />
      <List>
        {URLs.map((user, index) => (
          <ListItem
            sx={{
              borderBottom: "1px solid #C4C5D6",
              minHeight: "3rem",
            }}
            key={index}
            secondaryAction={
              <StyledIconButton onClick={handleMenuOpen}>
                <MoreVertIcon />
              </StyledIconButton>
            }
          >
            <BodyLarge>{user}</BodyLarge>
          </ListItem>
        ))}
      </List>
      <PoolCardMenu
        anchorEl={anchorEl}
        handleMenuClose={handleMenuClose}
      />
    </Box>
  );
};

export default UrlWhiteLists;
