import React from "react";
import {
  StyledIconButton,
  StyledToggleButton,
    PrimaryButton,
    SecondaryButton
} from "../../../../components/StyledComponents/Buttons/AuradineButtons";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterList from "@mui/icons-material/FilterList";
import { useState } from "react";
import {
  StyledDialog,
  StyledDialogTitle,
} from "../../../../components/StyledComponents/Dialog/Dialog";
import {
  H6HeadlineSmall,
  H8TitleMedium,
} from "../../../../components/StyledComponents/Typography/Typography.tsx";
import {
  DialogContent,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  ToggleButton,
  Autocomplete,
    DialogActions
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import PlaceHolderButton from "../../../../components/PlaceHolderButton/PlaceHolderButton";

const FilterModal = (props) => {
  const { open, setOpenFilter } = props;
  const formRef = React.useRef();
  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      selectedValue: null,
      selectedTuningConfiguration: null,
      tagsAutoComplete: [],
      statusAutoComplete: [],
      chassisSerialNumberAutoComplete: [],
      controlBoardSerialNumberAutoComplete: [],
        softwareVersionAutoComplete: []
    },
  });

  const selectedValue = watch("selectedButton");
  const selectedTuningConfiguration = watch("selectedTuningConfiguration");
  const selectedAutoCompleteOptions = watch("statusAutoComplete");
  const selectedTags = watch("tagsAutoComplete");
  const selectedChassisSerialNumber = watch("chassisSerialNumberAutoComplete");
  const selectedControlBoardSerialNumber = watch("controlBoardSerialNumberAutoComplete");
    const selectedSoftwareVersion = watch("softwareVersionAutoComplete");

  const onSubmit = (data) => {
    console.log(data);
    setOpenFilter(false);
  };

  const handleStatusAutoCompleteDeletion = (optionToDelete) => {
    const newValue = selectedAutoCompleteOptions.filter(
      (option) => option !== optionToDelete
    );
    setValue("statusAutoComplete", newValue);
  };

  const handleTagsAutoCompleteDeletion = (optionToDelete) => {
    const newValue = selectedTags.filter((option) => option !== optionToDelete);
    setValue("tagsAutoComplete", newValue);
  };

    const handleChassisSerialNumberAutoCompleteDeletion = (optionToDelete) => {
    const newValue = selectedChassisSerialNumber.filter((option) => option !== optionToDelete);
    setValue("chassisSerialNumberAutoComplete", newValue);
    };

    const handleControlBoardSerialNumberAutoCompleteDeletion = (optionToDelete) => {
    const newValue = selectedControlBoardSerialNumber.filter((option) => option !== optionToDelete);
    setValue("controlBoardSerialNumberAutoComplete", newValue);
    }

    const handleSoftwareVersionAutoCompleteDeletion = (optionToDelete) => {
    const newValue = selectedSoftwareVersion.filter((option) => option !== optionToDelete);
    setValue("softwareVersionAutoComplete", newValue);
    }

  const statusOptions = [
    "connected",
    "control board faulty",
    "disconnected",
    "Fan malfunction",
    "Hash board malfunction",
    "Hash rate lower than target",
    "Locate miner",
  ];
  //create tag options array with random tags
  const tagOptions = [
    "tag1",
    "tag2",
    "tag3",
    "tag4",
    "tag5",
    "tag6",
    "tag7",
    "tag8",
    "tag9",
    "tag10",
    "tag11",
    "tag12",
    "tag13",
    "tag14",
    "tag15",
  ];

  const chassisSerialNumberOptions = [
    "chassisSerialNumber1",
    "chassisSerialNumber2",
    "chassisSerialNumber3",
    "chassisSerialNumber4",
    "chassisSerialNumber5",
    "chassisSerialNumber6",
    "chassisSerialNumber7",
    "chassisSerialNumber8",
    "chassisSerialNumber9",
    "chassisSerialNumber10",
    "chassisSerialNumber11",
    "chassisSerialNumber12",
    "chassisSerialNumber13",
    "chassisSerialNumber14",
    "chassisSerialNumber15",
  ];

    const controlBoardSerialNumberOptions = [
    "controlBoardSerialNumber1",
    "controlBoardSerialNumber2",
    "controlBoardSerialNumber3",
    "controlBoardSerialNumber4",
    "controlBoardSerialNumber5",
    "controlBoardSerialNumber6",
    "controlBoardSerialNumber7",
    "controlBoardSerialNumber8",
    "controlBoardSerialNumber9",
    "controlBoardSerialNumber10",
    "controlBoardSerialNumber11",
    "controlBoardSerialNumber12",
    "controlBoardSerialNumber13",
    "controlBoardSerialNumber14",
    "controlBoardSerialNumber15",
    ];

    const softwareVersionOptions = [
        "1.0.0",
        "1.0.1",
        "1.0.2",
        "1.0.3",
        "1.0.4",
        "1.0.5",
        "1.0.6",
        "1.0.7",
        "1.0.8"
    ];

  return (
    <StyledDialog maxWidth={"lg"} open={open} hideBackdrop>
      <StyledDialogTitle sx={{ paddingTop: 0 }}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <H6HeadlineSmall style={{ textAlign: "left" }}>
            Filters
          </H6HeadlineSmall>
          <StyledIconButton
            onClick={() => setOpenFilter(false)}
            style={{ textAlign: "right" }}
          >
            <CloseIcon />
          </StyledIconButton>
        </Box>
      </StyledDialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <Box display={"flex"} flexDirection={"column"} gap={8}>
            <Box display={"flex"} flexDirection={"column"} gap={4} className>
              <H8TitleMedium>Average chip temperature</H8TitleMedium>
              <Box display="flex" flexDirection="row" gap={6} flexGrow={1}>
                <Controller
                  name="minimumAverageTemperature"
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      label="Min average temperature"
                      style={{ flex: 1 }}
                      type="number"
                    />
                  )}
                />

                <Controller
                  name="maximumAverageTemperature"
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      label="Max average temperature"
                      style={{ flex: 1 }}
                      type="number"
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={4}
              className="hashRateContainer"
            >
              <H8TitleMedium>Hash rate</H8TitleMedium>
              <Box display="flex" flexDirection="row" gap={6} flexGrow={1}>
                <Controller
                  name="minimumHashRate"
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      label="Min Hash rate"
                      style={{ flex: 1 }}
                    />
                  )}
                />

                <Controller
                  name="maximumHashRate"
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      label="Max Hash rate"
                      style={{ flex: 1 }}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={4}
              className="IPAddressContainer"
            >
              <H8TitleMedium>IP Address</H8TitleMedium>
              <Box display="flex" flexDirection="row" gap={6} flexGrow={1}>
                <Controller
                  name="fromIP"
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      label="From"
                      style={{ flex: 1 }}
                    />
                  )}
                />

                <Controller
                  name="toIP"
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      label="To"
                      style={{ flex: 1 }}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={4}
              className="modelContainer"
            >
              <H8TitleMedium>Model</H8TitleMedium>
              <Box display="flex" flexDirection="row" gap={6} flexGrow={1}>
                <FormControl fullWidth>
                  <InputLabel>Model</InputLabel>
                  <Controller
                    name="model"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Model"
                        style={{ flex: 1 }}
                        sx={{
                          padding: "1px",
                          "& .MuiSelect-select": {
                            padding: "0.53125rem 0.875rem", // Customize internal Select padding
                          },
                        }}
                      >
                        <MenuItem value="model1">Model1</MenuItem>
                        <MenuItem value="model2">Model2</MenuItem>
                        <MenuItem value="model3">Model3</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Box>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={4}
              className="modeContainer"
            >
              <H8TitleMedium>Mode</H8TitleMedium>
              <Box display="flex" flexDirection="row" gap={6} flexGrow={1}>
                <FormControl
                  sx={{ display: "flex", flexDirection: "row", gap: 5 }}
                >
                  <Controller
                    name="selectedButton"
                    control={control}
                    defaultValue={null}
                    render={({ field: { value, onChange } }) => (
                      <StyledToggleButton
                        value={"eco"}
                        onClick={() =>
                          onChange(selectedValue === "eco" ? null : "eco")
                        }
                        selected={selectedValue === "eco"}
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                      >
                        {selectedValue === "eco" ? (
                          <CheckIcon fontSize={"small"} />
                        ) : (
                          ""
                        )}{" "}
                        eco
                      </StyledToggleButton>
                    )}
                  />
                  <Controller
                    name="selectedButton"
                    control={control}
                    defaultValue={null}
                    render={({ field: { value, onChange } }) => (
                      <StyledToggleButton
                        value={"turbo"}
                        onClick={() =>
                          onChange(selectedValue === "turbo" ? null : "turbo")
                        }
                        selected={selectedValue === "turbo"}
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                      >
                        {selectedValue === "turbo" ? (
                          <CheckIcon fontSize={"small"} />
                        ) : (
                          ""
                        )}{" "}
                        turbo
                      </StyledToggleButton>
                    )}
                  />
                  <Controller
                    name="selectedButton"
                    control={control}
                    defaultValue={null}
                    render={({ field: { value, onChange } }) => (
                      <StyledToggleButton
                        value={"custom"}
                        onClick={() =>
                          onChange(selectedValue === "custom" ? null : "custom")
                        }
                        selected={selectedValue === "custom"}
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                      >
                        {selectedValue === "custom" ? (
                          <CheckIcon fontSize={"small"} />
                        ) : (
                          ""
                        )}{" "}
                        custom
                      </StyledToggleButton>
                    )}
                  />
                </FormControl>
              </Box>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={4}
              className="statusContainer"
            >
              <H8TitleMedium>Status</H8TitleMedium>
              <Box display="flex" flexDirection="row" flexGrow={1}>
                <FormControl fullWidth>
                  {/*<InputLabel>Status</InputLabel>*/}
                  <Controller
                    name="statusAutoComplete"
                    control={control}
                    defaultValue={null}
                    render={({ field: { onChange, value, ref } }) => (
                      <Autocomplete
                        fullWidth
                        multiple
                        disableCloseOnSelect
                        onChange={(_, data) => onChange(data)}
                        value={value || []}
                        clearIcon={null}
                        // sx={{ maxWidth: "19rem", minWidth: "19rem" }}
                        options={statusOptions} // Replace with your options
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Status"
                            variant="outlined"
                            inputRef={ref}
                          />
                        )}
                        renderTags={() => null}
                      />
                    )}
                  />
                </FormControl>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={4}
                maxWidth={"62.5rem"}
                flexWrap={"wrap"}
              >
                {selectedAutoCompleteOptions?.map((status, index) => (
                  <PlaceHolderButton
                    key={index}
                    onClose={() => handleStatusAutoCompleteDeletion(status)}
                    text={status}
                  />
                ))}
              </Box>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={4} className>
              <H8TitleMedium>Uptime</H8TitleMedium>
              <Box display="flex" flexDirection="row" gap={6} flexGrow={1}>
                <Controller
                  name="minUpTime"
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      label="Min Uptime"
                      style={{ flex: 1 }}
                    />
                  )}
                />

                <Controller
                  name="maxUpTime"
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      label="Max Temperature"
                      style={{ flex: 1 }}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={4} className>
              <H8TitleMedium>Power</H8TitleMedium>
              <Box display="flex" flexDirection="row" gap={6} flexGrow={1}>
                <Controller
                  name="minPower"
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      label="Min Power"
                      style={{ flex: 1 }}
                    />
                  )}
                />

                <Controller
                  name="maxPower"
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      label="Max power"
                      style={{ flex: 1 }}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={4} className>
              <H8TitleMedium>Power Efficiency</H8TitleMedium>
              <Box display="flex" flexDirection="row" gap={6} flexGrow={1}>
                <Controller
                  name="minimumPowerEfficiency"
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      label="Min Power Efficiency"
                      style={{ flex: 1 }}
                      type="number"
                    />
                  )}
                />

                <Controller
                  name="maximumPowerEfficiency"
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      label="Max Power Efficiency"
                      style={{ flex: 1 }}
                      type="number"
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={4}
              className="tuningConfigurationContainer"
            >
              <H8TitleMedium>Tuning configuration</H8TitleMedium>
              <Box display="flex" flexDirection="row" gap={6} flexGrow={1}>
                <FormControl
                  sx={{ display: "flex", flexDirection: "row", gap: 5 }}
                >
                  <Controller
                    name="selectedTuningConfiguration"
                    control={control}
                    defaultValue={null}
                    render={({ field: { value, onChange } }) => (
                      <StyledToggleButton
                        value={"miner"}
                        onClick={() =>
                          onChange(
                            selectedTuningConfiguration === "miner"
                              ? null
                              : "miner"
                          )
                        }
                        selected={selectedTuningConfiguration === "miner"}
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                      >
                        {selectedTuningConfiguration === "miner" ? (
                          <CheckIcon fontSize={"small"} />
                        ) : (
                          ""
                        )}{" "}
                        miner
                      </StyledToggleButton>
                    )}
                  />
                  <Controller
                    name="selectedTuningConfiguration"
                    control={control}
                    defaultValue={null}
                    render={({ field: { value, onChange } }) => (
                      <StyledToggleButton
                        value={"fluxvision"}
                        onClick={() =>
                          onChange(
                            selectedTuningConfiguration === "fluxvision"
                              ? null
                              : "fluxvision"
                          )
                        }
                        selected={selectedTuningConfiguration === "fluxvision"}
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                      >
                        {selectedTuningConfiguration === "fluxvision" ? (
                          <CheckIcon fontSize={"small"} />
                        ) : (
                          ""
                        )}
                        fluxvision
                      </StyledToggleButton>
                    )}
                  />
                </FormControl>
              </Box>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={4}
              className="tagsContainer"
            >
              <H8TitleMedium>Select Tags</H8TitleMedium>
              <Box display="flex" flexDirection="row" flexGrow={1}>
                <FormControl fullWidth>
                  {/*<InputLabel>Status</InputLabel>*/}
                  <Controller
                    name="tagsAutoComplete"
                    control={control}
                    defaultValue={null}
                    render={({ field: { onChange, value, ref } }) => (
                      <Autocomplete
                        fullWidth
                        multiple
                        disableCloseOnSelect
                        onChange={(_, data) => onChange(data)}
                        value={value || []}
                        clearIcon={null}
                        // sx={{ maxWidth: "19rem", minWidth: "19rem" }}
                        options={tagOptions} // Replace with your options
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Status"
                            variant="outlined"
                            inputRef={ref}
                          />
                        )}
                        renderTags={() => null}
                      />
                    )}
                  />
                </FormControl>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={4}
                maxWidth={"62.5rem"}
                flexWrap={"wrap"}
              >
                {selectedTags?.map((tag, index) => (
                  <PlaceHolderButton
                    key={index}
                    onClose={() => handleTagsAutoCompleteDeletion(tag)}
                    text={tag}
                  />
                ))}
              </Box>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={4}
              className="chassisSerialNumberContainer"
            >
              <H8TitleMedium>Chassis Serial Number</H8TitleMedium>
              <Box display="flex" flexDirection="row" flexGrow={1}>
                <FormControl fullWidth>
                  {/*<InputLabel>Status</InputLabel>*/}
                  <Controller
                    name="chassisSerialNumberAutoComplete"
                    control={control}
                    defaultValue={null}
                    render={({ field: { onChange, value, ref } }) => (
                      <Autocomplete
                        fullWidth
                        multiple
                        disableCloseOnSelect
                        onChange={(_, data) => onChange(data)}
                        value={value || []}
                        clearIcon={null}
                        // sx={{ maxWidth: "19rem", minWidth: "19rem" }}
                        options={chassisSerialNumberOptions} // Replace with your options
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Chassis Serial Number"
                            variant="outlined"
                            inputRef={ref}
                          />
                        )}
                        renderTags={() => null}
                      />
                    )}
                  />
                </FormControl>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={4}
                maxWidth={"62.5rem"}
                flexWrap={"wrap"}
              >
                {selectedChassisSerialNumber?.map((chassisSerialNo, index) => (
                  <PlaceHolderButton
                    key={index}
                    onClose={() => handleTagsAutoCompleteDeletion(chassisSerialNo)}
                    text={chassisSerialNo}
                  />
                ))}
              </Box>
            </Box>
              <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={4}
                  className="controlBoardContainer"
              >
                  <H8TitleMedium>Control Board Serial Number</H8TitleMedium>
                  <Box display="flex" flexDirection="row" flexGrow={1}>
                      <FormControl fullWidth>
                          {/*<InputLabel>Status</InputLabel>*/}
                          <Controller
                              name="controlBoardSerialNumberAutoComplete"
                              control={control}
                              defaultValue={null}
                              render={({ field: { onChange, value, ref } }) => (
                                  <Autocomplete
                                      fullWidth
                                      multiple
                                      disableCloseOnSelect
                                      onChange={(_, data) => onChange(data)}
                                      value={value || []}
                                      clearIcon={null}
                                      // sx={{ maxWidth: "19rem", minWidth: "19rem" }}
                                      options={controlBoardSerialNumberOptions} // Replace with your options
                                      renderInput={(params) => (
                                          <TextField
                                              {...params}
                                              label="Control Board Serial Number"
                                              variant="outlined"
                                              inputRef={ref}
                                          />
                                      )}
                                      renderTags={() => null}
                                  />
                              )}
                          />
                      </FormControl>
                  </Box>
                  <Box
                      display={"flex"}
                      flexDirection={"row"}
                      gap={4}
                      maxWidth={"62.5rem"}
                      flexWrap={"wrap"}
                  >
                      {selectedControlBoardSerialNumber?.map((controlBoardSerialNumber, index) => (
                          <PlaceHolderButton
                              key={index}
                              onClose={() => handleControlBoardSerialNumberAutoCompleteDeletion(controlBoardSerialNumber)}
                              text={controlBoardSerialNumber}
                          />
                      ))}
                  </Box>
              </Box>
              <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={4}
                  className="versionContainer"
              >
                  <H8TitleMedium>Software version</H8TitleMedium>
                  <Box display="flex" flexDirection="row" flexGrow={1}>
                      <FormControl fullWidth>
                          {/*<InputLabel>Status</InputLabel>*/}
                          <Controller
                              name="softwareVersionAutoComplete"
                              control={control}
                              defaultValue={null}
                              render={({ field: { onChange, value, ref } }) => (
                                  <Autocomplete
                                      fullWidth
                                      multiple
                                      disableCloseOnSelect
                                      onChange={(_, data) => onChange(data)}
                                      value={value || []}
                                      clearIcon={null}
                                      // sx={{ maxWidth: "19rem", minWidth: "19rem" }}
                                      options={softwareVersionOptions} // Replace with your options
                                      renderInput={(params) => (
                                          <TextField
                                              {...params}
                                              label="Software Version"
                                              variant="outlined"
                                              inputRef={ref}
                                          />
                                      )}
                                      renderTags={() => null}
                                  />
                              )}
                          />
                      </FormControl>
                  </Box>
                  <Box
                      display={"flex"}
                      flexDirection={"row"}
                      gap={4}
                      maxWidth={"62.5rem"}
                      flexWrap={"wrap"}
                  >
                      {selectedSoftwareVersion?.map((softwareVersion, index) => (
                          <PlaceHolderButton
                              key={index}
                              onClose={() => handleSoftwareVersionAutoCompleteDeletion(softwareVersion)}
                              text={softwareVersion}
                          />
                      ))}
                  </Box>
              </Box>
          </Box>
        </form>
      </DialogContent>
        <DialogActions>
            <Box display={"flex"} flexDirection={"row"} gap={4}>
               <SecondaryButton onClick={() => setOpenFilter(false)}>Cancel</SecondaryButton>
                <PrimaryButton type="button" onClick={() => formRef.current.submit()}>Apply</PrimaryButton>
            </Box>
        </DialogActions>
    </StyledDialog>
  );
};

const MinerFilters = () => {
  const [openFilterModal, setOpenFilter] = useState(false);
  return (
    <>
      <StyledIconButton
        open={openFilterModal}
        onClick={() => setOpenFilter(true)}
      >
        <FilterList size="small" />
      </StyledIconButton>
      <FilterModal open={openFilterModal} setOpenFilter={setOpenFilter} />
    </>
  );
};

export default MinerFilters;
