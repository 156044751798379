import React from "react";
import {
  Box,
  List,
  ListItem,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  BodyLarge,
  H7TitleLarge,
  H9TitleSmall,
  LabelMedium,
  LabelSmall,
} from "../../../components/StyledComponents/Typography/Typography.tsx";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { StyledIconButton } from "../../../components/StyledComponents/Buttons/AuradineButtons.js";
import Styled from "@emotion/styled";
import usePoolsPageContext from "../../context/usePriceConfigurationContext.js";
import useAppContextProvider from "../../../AppContext/useAppContextProvider.js";
import usePriceConfigurationContext from "../../context/usePriceConfigurationContext.js";
import ActionDialog from "../../../components/ActionDialog/ActionDialog.js";
import { set } from "date-fns";

const StyledMenu = Styled(Menu)({
  "& .MuiList-root": {
    backgroundColor: "#eeedf7",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
  },
});

const StyledMenuItem = Styled(MenuItem)({
  padding: "0.5rem 0.75rem",
  minWidth: "12.5rem",
  backgroundColor: "#eeedf7",
  "&:hover": {
    backgroundColor: "#E2E1EC",
  },
});

export const PriceConfigurationCardMenu = (props) => {
  const {
    anchorEl,
    handleMenuClose,
    handleMenuItemClick,
    index,
  } = props;

  return (
    <StyledMenu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      <StyledMenuItem
        onClick={() => handleMenuItemClick("edit", index)}
      >
        Edit
      </StyledMenuItem>
      <StyledMenuItem
        onClick={() => handleMenuItemClick("delete", index)}
      >
        Delete
      </StyledMenuItem>
    </StyledMenu>
  );
};

const PriceConfigurationCard = (props) => {
  const { url, username, password, workIdentifier, index } =
    props;

  const {
    mockData,
    setMockData,
    setSiteConfigurationDataToEdit,
    setEditConfigModeEnabled,
    setOpenAddPriceConfigModal,
    deletionDialogOpen,
    setDeletionDialogOpen,
    setDeleteDialogHeaderMessage,
    setDeleteDialogBodyMessage,
    deleteDialogBodyMessage,
    deleteDialogHeaderMessage,
    siteConfigurationDataToEdit,
  } = usePriceConfigurationContext();

  const { setToastOpen, setToastMessage } =
    useAppContextProvider();

  const handleDeletionDialogClose = () => {
    setDeletionDialogOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenuItemClick = (key, index) => {
    if (key === "edit") {
      setEditConfigModeEnabled(true);
      setSiteConfigurationDataToEdit(mockData[index]);
      setOpenAddPriceConfigModal(true);
    } else if (key === "delete") {
      setDeletionDialogOpen(true);
      setSiteConfigurationDataToEdit(mockData[index]);
      setDeleteDialogHeaderMessage(
        `Delete ${mockData[index].priceConfigName}`
      );
      setDeleteDialogBodyMessage(
        "Are you sure you want to delete this configuration?"
      );
    }
    handleMenuClose();
  };

  const handleConfigurationDeletion = () => {
    const updatedData = [...mockData];
    const configurationWithoutDeletedData =
      updatedData.filter(
        (data) =>
          data.priceConfigName !==
          siteConfigurationDataToEdit?.priceConfigName
      );

    setMockData(configurationWithoutDeletedData);
    setDeletionDialogOpen(false);
    setToastOpen(true);
    setToastMessage("Configuration deleted successfully");
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <List
        dense
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        {[...mockData].map((data, index) => (
          <React.Fragment key={index}>
            <ListItem
              key={index}
              secondaryAction={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.62rem",
                  }}
                >
                  <LabelSmall style={{ color: "grey" }}>
                    {data?.costOfElectricity}
                  </LabelSmall>
                  <StyledIconButton
                    onClick={handleMenuOpen}
                  >
                    <MoreVertIcon />
                  </StyledIconButton>
                </Box>
              }
              sx={{
                padding: " 0.75rem 1rem",
                minHeight: "5.25rem",
                borderBottom: "1px solid #C4C5D6",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "1rem",
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                >
                  <LabelMedium>
                    {data?.prizeZone}
                  </LabelMedium>
                  <BodyLarge>
                    {data?.priceConfigName}
                  </BodyLarge>
                  {`${data?.independentSystemOperator} • ${data?.powerPurchaseAgreement}`}
                </Box>
              </Box>
            </ListItem>
            <PriceConfigurationCardMenu
              {...{
                anchorEl,
                handleMenuClose,
                handleMenuItemClick,
                index,
              }}
            />
          </React.Fragment>
        ))}
      </List>
      <ActionDialog
        open={deletionDialogOpen}
        onClose={handleDeletionDialogClose}
        headerMessage={deleteDialogHeaderMessage}
        subMessage={deleteDialogBodyMessage}
        buttonText={"Delete"}
        deleteAction={handleConfigurationDeletion}
      />
    </>
  );
};

export default PriceConfigurationCard;
