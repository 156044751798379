import React, {
  createContext,
  useMemo,
  useState,
} from "react";

export const DemandResponseConfigurationContext =
  createContext();

export const DemandResponseConfigurationContextProvider = (
  props
) => {
  const [selectedDate, setSelectedDate] = useState(
    new Date()
  );
  const [timeEvents, setTimeEvents] = useState([]);
  const [
    openPowerCurtailOperationModal,
    setOpenPowerCurtailOperationModal,
  ] = useState(false);
  const [openQSEDialog, setOpenQSEDialog] = useState(false);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [eventModalData, setEventModalData] = useState([]);
  const [eventModalAnchor, setEventModalAnchor] =
    useState(null);
  const [editModeOn, setEditModeOn] = useState(false);
  const contextValue = useMemo(() => {
    return {
      selectedDate,
      setSelectedDate,
      timeEvents,
      setTimeEvents,
      openQSEDialog,
      setOpenQSEDialog,
      openPowerCurtailOperationModal,
      setOpenPowerCurtailOperationModal,
      calendarEvents,
      setCalendarEvents,
      eventModalData,
      setEventModalData,
      eventModalAnchor,
      setEventModalAnchor,
      editModeOn,
      setEditModeOn,
    };
  }, [
    selectedDate,
    setSelectedDate,
    openQSEDialog,
    setOpenQSEDialog,
    timeEvents,
    setTimeEvents,
    openPowerCurtailOperationModal,
    setOpenPowerCurtailOperationModal,
    calendarEvents,
    setCalendarEvents,
    eventModalData,
    setEventModalData,
    eventModalAnchor,
    setEventModalAnchor,
    editModeOn,
    setEditModeOn,
  ]);

  return (
    <DemandResponseConfigurationContext.Provider
      value={contextValue}
    >
      {props.children}
    </DemandResponseConfigurationContext.Provider>
  );
};
