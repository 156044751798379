import { Box, Typography } from "@mui/material";
import React from "react";
import RafikiImage from "../../../assets/rafiki.svg";
import { BodyLarge } from "../Typography/Typography.tsx";

type AuradineErrorStateProps = {
  message: string;
};

const PoolNotSelectedState: React.FC<
  AuradineErrorStateProps
> = (
  props: AuradineErrorStateProps
): React.ReactElement => {
  const { message } = props;
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent={"flex-start"}
      alignItems={"center"}
      alignContent={"center"}
      gap={4}
      height={"100%"}
      flexGrow={1}
    >
      <img
        style={{ width: "20rem", height: "20rem" }}
        src={RafikiImage}
        alt="empty basket"
      />
      <BodyLarge>{message}</BodyLarge>
    </Box>
  );
};

export default PoolNotSelectedState;
