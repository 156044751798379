import React, {
  createContext,
  useMemo,
  useState,
} from "react";

export const priceConfigurationContext = createContext();
const initialFormDataForAddingPriceConfig = {
  independentSystemOperator: "",
  powerPurchaseAgreement: "",
  prizeZone: "",
  costOfElectricity: "",
  priceConfigName: "",
  siteConfig: [],
};

const priceConfigurationMockData = [
  {
    priceConfigName: "bitties",
    independentSystemOperator: "ERCOT",
    prizeZone: "LZ_Houston",
    powerPurchaseAgreement: "Hegde",
    costOfElectricity: "61",
    siteConfig: [],
  },
  {
    priceConfigName: "FioriPrice",
    independentSystemOperator: "ERCOT",
    prizeZone: "LZ_Houston",
    powerPurchaseAgreement: "Hegde",
    costOfElectricity: "61",
    siteConfig: [],
  },
];

export const PriceConfigurationContextProvider = (
  props
) => {
  const [siteSelected, setSiteSelected] = useState(false);
  const [siteSelectedName, setSiteSelectedName] =
    useState("");
  const [groupSelectedName, setGroupSelectedName] =
    useState("");
  const [
    openAddPriceConfigModal,
    setOpenAddPriceConfigModal,
  ] = useState(false);
  const [mockData, setMockData] = React.useState([
    ...priceConfigurationMockData,
  ]);
  const [
    siteConfigurationDataToEdit,
    setSiteConfigurationDataToEdit,
  ] = React.useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [priceConfig, setPriceConfig] = useState({
    ...initialFormDataForAddingPriceConfig,
  });
  const [activeStep, setActiveStep] = useState(0);
  const [totalSteps, setTotalSteps] = useState(4);
  const [editConfigModeEnabled, setEditConfigModeEnabled] =
    useState(false);
  const [editModeOn, setEditModeOn] = useState(false);
  const [deletionDialogOpen, setDeletionDialogOpen] =
    useState(false);
  const [
    deleteDialogHeaderMessage,
    setDeleteDialogHeaderMessage,
  ] = useState("");
  const [
    deleteDialogBodyMessage,
    setDeleteDialogBodyMessage,
  ] = useState("");

  const contextValue = useMemo(() => {
    return {
      siteSelected,
      setSiteSelected,
      siteSelectedName,
      setSiteSelectedName,
      groupSelectedName,
      setGroupSelectedName,
      openAddPriceConfigModal,
      editConfigModeEnabled,
      setEditConfigModeEnabled,
      setOpenAddPriceConfigModal,
      mockData,
      totalSteps,
      searchTerm,
      activeStep,
      setTotalSteps,
      setActiveStep,
      setSearchTerm,
      setMockData,
      siteConfigurationDataToEdit,
      setSiteConfigurationDataToEdit,
      priceConfig,
      setPriceConfig,
      initialFormDataForAddingPriceConfig,
      deletionDialogOpen,
      setDeletionDialogOpen,
      deleteDialogHeaderMessage,
      setDeleteDialogHeaderMessage,
      deleteDialogBodyMessage,
      setDeleteDialogBodyMessage,
    };
  }, [
    siteSelected,
    siteSelectedName,
    groupSelectedName,
    openAddPriceConfigModal,
    editConfigModeEnabled,
    setEditConfigModeEnabled,
    mockData,
    activeStep,
    priceConfig,
    siteConfigurationDataToEdit,
    setActiveStep,
    setSiteConfigurationDataToEdit,
    setPriceConfig,
    setMockData,
    setSiteSelected,
    setSiteSelectedName,
    setGroupSelectedName,
    setOpenAddPriceConfigModal,
    initialFormDataForAddingPriceConfig,
    deletionDialogOpen,
    setDeletionDialogOpen,
    deleteDialogHeaderMessage,
    setDeleteDialogHeaderMessage,
    deleteDialogBodyMessage,
    setDeleteDialogBodyMessage,
  ]);

  return (
    <priceConfigurationContext.Provider
      value={contextValue}
    >
      {props.children}
    </priceConfigurationContext.Provider>
  );
};
