import React from "react";
import {
  Box,
  InputBase,
  Paper,
  Tooltip,
  styled,
  IconButton,
  Stepper,
} from "@mui/material";

import {
  SecondaryButton,
  PrimaryButton,
} from "../../../components/StyledComponents/Buttons/AuradineButtons.js";

import AddIcon from "@mui/icons-material/Add";
import { useHistory, Link } from "react-router-dom";
import { Search as SearchIcon } from "@mui/icons-material";
import usePriceConfigurationContext from "../../context/usePriceConfigurationContext.js";
import AddPriceConfigDialog from "../AddPriceConfigDialog/AddPriceConfigDialog.js";
import { set } from "date-fns";

export const PriceConfigurationHeader = React.memo(
  (props) => {
    const history = useHistory();
    const {
      setOpenAddPriceConfigModal,
      mockData,
      setMockData,
    } = usePriceConfigurationContext();
    const [mockDataCopy, setMockDataCopy] = React.useState(
      []
    );

    React.useEffect(() => {
      setMockDataCopy(mockData);
    }, []);

    const filterMockData = (event) => {
      const searchValue = event.target.value.toLowerCase();
      if (searchValue) {
        const filteredMockData = mockData.filter((data) =>
          data.priceConfigName
            ?.toLowerCase()
            .includes(searchValue)
        );
        setMockData(filteredMockData);
      } else {
        setMockData(mockDataCopy);
      }
    };

    return (
      <Box
        className={"table-container-header"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <Paper
            component="form"
            elevation={0}
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "100%",
              backgroundColor: "#E8E7F1",
              borderRadius: "1.75rem",
              paddingLeft: "1rem",
            }}
          >
            <Tooltip
              // title={searchValue}
              disableFocusListener
              disableTouchListener
            >
              <InputBase
                sx={{ flex: 1, minWidth: "22.5rem" }}
                placeholder="Search for a  price configuration"
                onChange={(event) => {
                  filterMockData(event);
                }}
                inputProps={{
                  "aria-label": "search google maps",
                }}
              />
            </Tooltip>
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </Box>
        <Box display={"flex"} flexDirection={"row"} gap={2}>
          <Box
            className={"action-container"}
            display={"flex"}
            flexDirection={"row"}
            gap={2}
          >
            <Box>
              <PrimaryButton
                onClick={() =>
                  setOpenAddPriceConfigModal(true)
                }
              >
                Add <AddIcon />
              </PrimaryButton>
            </Box>
          </Box>
        </Box>
        <AddPriceConfigDialog />
      </Box>
    );
  }
);
