/**
 * Renders a tree view of sites and groups.
 * @param {Object} props - The component props.
 * @param {Array} props.siteAndGroups - The array of site and group data.
 * @param {Function} props.onSitesAndGroupSearch - The callback function for searching sites and groups.
 * @returns {JSX.Element} The rendered component.
 */
import React, { useState } from "react";
import { Box, Grow, Collapse, Fade } from "@mui/material";
import { StyledIconButton } from "../../../../components/StyledComponents/Buttons/AuradineButtons.js";
import {
  BodyLarge,
  LabelSmall,
} from "../../../../components/StyledComponents/Typography/Typography.tsx";
import usePoolsPageContext from "../../../context/usePoolsPageContext";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { set } from "date-fns";
import Close from "@mui/icons-material/Close";

/**The SiteAndGroupTreeItem component takes in several props:
site: Represents the current site.
setSiteSelected: A function to update the selected site.
siteSelected: Represents the currently selected site.
setSiteSelectedName: A function to update the name of the selected site.
setGroupSelectedName: A function to update the name of the selected group.
siteSelectedName: Represents the name of the currently selected site.
groupSelectedName: Represents the name of the currently selected group. */

//Inside the component, there is a state variable called treeOpen initialized with a value of false. This state variable will be used to control the visibility of the tree.
const SiteAndGroupTreeItem = (props) => {
  const {
    site,
    setSiteSelected,
    siteSelected,
    setSiteSelectedName,
    setGroupSelectedName,
    siteSelectedName,
    groupSelectedName,
  } = props;
  const [treeOpen, setTreeOpen] = useState(false);
  const {
    setSiteSelectedName: setSiteSelectedNameContext,
    siteSelectedName: siteSelectedNameContext,
  } = usePoolsPageContext();

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      borderBottom={"1px solid  #C4C5D6"}
    >
      <Box
        className={"site_information"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        minHeight={"2rem"}
        padding={" 0.5rem 1.5rem 0.5rem 1rem"}
        onClick={() => {
          setSiteSelected(true);
          setSiteSelectedName(site?.label);
          setGroupSelectedName("");
          console.log(
            "siteSelectedNameContext",
            siteSelectedNameContext
          );
          console.log("site?.label", site?.label);
          setSiteSelectedNameContext(site?.label);
        }}
        gap={4}
        sx={{
          cursor: "pointer",
          transition: "all 0.3s ease-in-out",
          backgroundColor:
            siteSelectedName === site?.label && siteSelected
              ? "#C7D1FF"
              : "",
          borderRadius: "6.25rem",
          "&:hover": {
            backgroundColor:
              siteSelectedName === site?.label &&
              siteSelected
                ? "#C7D1FF"
                : "rgba(26, 27, 34, 0.08)",
            borderRadius: "6.25rem",
          },
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          flexGrow={1}
          alignItems={"center"}
        >
          {siteSelectedName === site?.label &&
            siteSelected && (
              <StyledIconButton
                onClick={(event) => {
                  event.stopPropagation();
                  setGroupSelectedName("");
                  setSiteSelectedName("");
                  setSiteSelected(false);
                  setSiteSelectedNameContext("");
                }}
              >
                <Close
                  fontSize="small"
                  sx={{ color: "#000000" }}
                />
              </StyledIconButton>
            )}
          <BodyLarge>{site?.label}</BodyLarge>{" "}
          <LabelSmall> {site?.totalMinerCount} </LabelSmall>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          gap={2.48}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          {site.children?.length > 0 && (
            <StyledIconButton
              onClick={(event) => {
                event.stopPropagation();
                setTreeOpen((prev) => !prev);
              }}
              disabled={
                site?.children &&
                site?.children?.length === 0
              }
            >
              {treeOpen ? (
                <>
                  <ArrowDropDownIcon />
                </>
              ) : (
                <>
                  <ArrowDropUpIcon />
                </>
              )}
            </StyledIconButton>
          )}
        </Box>
      </Box>
      {treeOpen &&
        site?.children &&
        site?.children?.length > 0 &&
        site.children.map((children, index) => {
          return (
            <Fade in={treeOpen} key={index}>
              <Box
                className={"group_information"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                padding={" 0.5rem 1.5rem 0.5rem 1rem"}
                gap={4}
                onClick={() => {
                  setSiteSelected(false);
                  console.log("children", children?.label);
                  console.log("site", site?.label);
                  setGroupSelectedName(children?.label);
                  setSiteSelectedName(site?.label);
                }}
                sx={{
                  cursor: "pointer",
                  transition: "all 0.3s ease-in-out",
                  borderRadius: "6.25rem",
                  backgroundColor:
                    groupSelectedName === children?.label &&
                    siteSelectedName === site?.label &&
                    !siteSelected
                      ? "#C7D1FF"
                      : "",
                  "&:hover": {
                    backgroundColor:
                      groupSelectedName ===
                        children?.label &&
                      siteSelectedName === site?.label &&
                      !siteSelected
                        ? "#C7D1FF"
                        : "rgba(26, 27, 34, 0.08)",
                    borderRadius: "6.25rem",
                    // padding: "1rem 1.5rem",
                  },
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  flexGrow={1}
                  alignItems={"center"}
                >
                  {groupSelectedName === children?.label &&
                    siteSelectedName === site?.label &&
                    !siteSelected && (
                      <StyledIconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          setGroupSelectedName("");
                          setSiteSelectedName("");
                          setSiteSelected(false);
                        }}
                      >
                        <Close
                          fontSize="small"
                          sx={{ color: "#000000" }}
                        />
                      </StyledIconButton>
                    )}
                  <BodyLarge>{children?.label}</BodyLarge>{" "}
                  <LabelSmall>
                    {" "}
                    {children?.minerCount}{" "}
                  </LabelSmall>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  gap={2.48}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                >
                  <Box
                    width={"2.125rem"}
                    height={"2.125rem"}
                  ></Box>
                </Box>
              </Box>
            </Fade>
          );
        })}
    </Box>
  );
};

//A site and group tree is a hierarchical representation of sites and groups from our groups and sites management system.
const SiteAndGroupTree = (props) => {
  const { siteAndGroups, onSitesAndGroupSearch } = props;
  const [treeOpen, setTreeOpen] = React.useState(false);
  const [siteSelected, setSiteSelected] = useState(false);
  const [siteSelectedName, setSiteSelectedName] =
    useState("");
  const [groupSelectedName, setGroupSelectedName] =
    useState("");

  // const { treeOpen, setTreeOpen, siteSelected, setSiteSelected, siteSelectedName, setSiteSelectedName, groupSelectedName, setGroupSelectedName } = usePoolsPageContext();
  const [expansionDisabled, setExpansionDisabled] =
    useState(false);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      flexGrow={1}
      overflow={"auto"}
      maxHeight={"60vh"}
    >
      {siteAndGroups.length > 0 &&
        siteAndGroups?.map((site, index) => (
          <SiteAndGroupTreeItem
            key={index}
            site={site}
            treeOpen={treeOpen}
            setTreeOpen={setTreeOpen}
            siteSelected={siteSelected}
            setSiteSelected={setSiteSelected}
            siteSelectedName={siteSelectedName}
            setSiteSelectedName={setSiteSelectedName}
            groupSelectedName={groupSelectedName}
            setGroupSelectedName={setGroupSelectedName}
          />
        ))}
      {siteAndGroups.length === 0 && (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexGrow={1}
        >
          <BodyLarge>No searches found</BodyLarge>
        </Box>
      )}
    </Box>
  );
};

export default SiteAndGroupTree;
