// @ts-nocheck
import React, { useEffect } from "react";
import { Box, Grid, Container } from "@mui/material";

import "reactjs-popup/dist/index.css";
import useAppContextProvider from "../AppContext/useAppContextProvider.js";

import { useHistory, useLocation } from "react-router-dom";
import {
  BodyLarge,
  H7TitleLarge,
} from "../components/StyledComponents/Typography/Typography.tsx";

import usePoolsPageContext from "./context/usePriceConfigurationContext.js";
import { useQuery } from "@tanstack/react-query";
import { GetUserUseQuery } from "../api/api.js";
import { PriceConfigurationHeader } from "./Components/PriceConfigurationHeader/PriceConfigurationHeader.js";

import PriceConfigurationCard from "./Components/PriceConfigurationCard/PriceConfigurationCard.js";

export const HeaderContainer = React.memo((props) => {
  const { data } = props;
  const history = useHistory();
  return (
    <Box
      className={"header-container"}
      display={"flex"}
      justifyContent="space-between"
      flexDirection="column"
      gap={4}
      marginBottom={"1.5rem"}
    >
      <Box
        className={"title-box"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        gap={3.5}
      >
        <H7TitleLarge>Price Configuration</H7TitleLarge>
        <BodyLarge>
          Manage price configurations to control costs and
          optimize profitability for your mining operations.
        </BodyLarge>
      </Box>
    </Box>
  );
});

/**
 * Represents the MinerPage component.
 * This component displays a table of miner devices and provides functionality to interact with them.
 */
export default function PriceConfigurationPage() {
  const history = useHistory();
  const location = useLocation();
  const { authToken } = useAppContextProvider();

  const [totalCount, setTotalCount] = React.useState(null);

  const limit = 25;

  const {
    data: userInfo,
    isFetched: isUserInfoFetched,
    isFetching: isUserInfoFetching,
  } = useQuery({
    queryKey: ["userInfo", authToken],
    queryFn: () => GetUserUseQuery(authToken),
    enabled: !!authToken,
  });
  const user = userInfo?.user;

  return (
    //@ts-nocheck
    <Container
      component="div"
      maxWidth="100%"
      sx={{
        padding: "2rem 3.5rem !important ",
        margin: 0,
        backgroundColor: "#FAF8FF",
        overflowY: "auto",
        height: "100vh",
      }}
    >
      <HeaderContainer />
      <Grid
        container
        className={"miner-page-table-container"}
        display={"flex"}
        flexDirection={"row"}
        spacing={4}
        sx={{ transition: "all 0.3s ease-in-out" }}
      >
        <Grid
          item
          xs={12}
          sx={{
            transition: "width 0.3s ease-in-out", // Smooth transition for width
          }}
        >
          <Box
            className={"miner-page-table"}
            display={"flex"}
            flexDirection={"row"}
            backgroundColor={"#F3F2FD"}
            padding={"0.75rem 0  0.75rem 0"}
            borderRadius={"1rem"}
            sx={{
              transition: "width 0.3s ease-in-out", // Smooth transition for width
            }}
          >
            <Box
              className={"table-container"}
              display={"flex"}
              flexDirection={"column"}
              padding={"0 0.75rem 0 0.75rem"}
              height={"auto"}
              overflow={"auto"}
              minWidth={"0"}
              gap={4}
              flex={3}
              flexShrink={1}
            >
              <PriceConfigurationHeader />
              <Box
                className={"Datagrid-container"}
                display={"flex"}
                flexShrink={1}
                flexGrow={1}
                sx={{ overflowX: "auto" }}
                height="calc(100vh - 17rem)"
              >
                <PriceConfigurationCard />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
