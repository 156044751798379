// @ts-nocheck
import React, { useEffect } from "react";
import {
  Box,
  Grid,
  Container,
  LinearProgress,
} from "@mui/material";
import "reactjs-popup/dist/index.css";
import useAppContextProvider from "../../../AppContext/useAppContextProvider.js";

import { useHistory, useLocation } from "react-router-dom";
import {
  BodyLarge,
  H7TitleLarge,
} from "../../../components/StyledComponents/Typography/Typography.tsx";

import UrlWhiteLists from "./URLs/UrlWhiteLists.js";
import UsersWhiteLists from "./Users/UsersWhiteLists.js";

import { WhiteListsLeftPanel } from "../../Components/WhiteListsLeftPanel/WhiteListsLeftPanel.js";
import { Switch, Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
import {
  PoolsPageContext,
  PoolsPageContextProvider,
} from "../../context/PoolsPageContextProvider.js";
import { WhiteListURLHeader } from "../../Components/WhiteListUrlsHeader/WhiteListUrlsHeader.js";

export const HeaderContainer = React.memo((props) => {
  const { data } = props;

  const history = useHistory();
  // const shouldAddPoolConfigurationEnabled =
  //   data?.find(
  //     (site) =>
  //       site.label?.toLowerCase() === siteSelectedName
  //   )[0]?.pools.length < 4;
  return (
    <Box
      className={"header-container"}
      display={"flex"}
      justifyContent="space-between"
      flexDirection="column"
      gap={4}
      marginBottom={"1.5rem"}
    >
      <Box
        className={"title-box"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        gap={3.5}
      >
        <H7TitleLarge>{`Pool Configuration > whitelists`}</H7TitleLarge>
        <BodyLarge>
          Manage your whitelists for your users and URLs.
        </BodyLarge>
      </Box>
    </Box>
  );
});

const WhiteListsPageHeader = () => {
  return <></>;
};

/**
 * Represents the MinerPage component.
 * This component displays a table of miner devices and provides functionality to interact with them.
 */
export default function WhiteListsPage() {
  const [leftSidePanelOpen, setLeftSidePanelOpen] =
    React.useState(false);
  const [gridHeight, setGridHeight] = React.useState(0);
  const tableContainerRef = React.useRef(null);
  const history = useHistory();
  const location = useLocation();
  const { authToken } = useAppContextProvider();

  // const [skip, setSkip] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(null);

  const limit = 25;
  //fetching to get userPreferences to set the tableGroup and detailGroup from user object. If not present, we are setting default values

  return (
    //@ts-nocheck
    <PoolsPageContextProvider>
      <Container
        component="div"
        maxWidth="100%"
        sx={{
          padding: "2rem 3.5rem !important ",
          margin: 0,
          backgroundColor: "#FAF8FF",
          overflowY: "auto",
          height: "100vh",
        }}
      >
        <HeaderContainer />
        <Grid
          container
          className={"miner-page-table-container"}
          display={"flex"}
          flexDirection={"row"}
          spacing={4}
          sx={{ transition: "all 0.3s ease-in-out" }}
        >
          <Grid
            ref={tableContainerRef}
            item
            xs={12}
            sx={{
              transition: "width 0.3s ease-in-out", // Smooth transition for width
            }}
          >
            <Box
              className={"miner-page-table"}
              display={"flex"}
              flexDirection={"row"}
              backgroundColor={"#F3F2FD"}
              padding={"0.75rem 0  0.75rem 0"}
              borderRadius={"1rem"}
              sx={{
                transition: "width 0.3s ease-in-out", // Smooth transition for width
              }}
            >
              <WhiteListsLeftPanel />
              <Box
                className={"table-container"}
                display={"flex"}
                flexDirection={"column"}
                padding={"0 0.75rem 0 0.75rem"}
                height={"auto"}
                overflow={"auto"}
                minWidth={"0"}
                gap={4}
                flex={3}
                flexShrink={1}
              >
                <WhiteListsPageHeader />
                <Box
                  className={"Datagrid-container"}
                  display={"flex"}
                  flexShrink={1}
                  flexGrow={1}
                  overflowX={"auto"}
                  height="calc(100vh - 17rem)"
                >
                  <Switch>
                    <Route exact path="/pools/whitelist">
                      <Redirect to="/pools/whitelist/url" />
                    </Route>
                    <Route
                      path="/pools/whitelist/url"
                      component={UrlWhiteLists}
                    />
                    <Route
                      path="/pools/whitelist/users"
                      component={UsersWhiteLists}
                    />
                  </Switch>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </PoolsPageContextProvider>
  );
}
