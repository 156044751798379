import React from "react";
import {
  Box,
  Collapse,
  IconButton,
  InputBase,
  Paper,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import { StyledIconButton } from "../../../components/StyledComponents/Buttons/AuradineButtons.js";
import { H7TitleLarge } from "../../../components/StyledComponents/Typography/Typography.tsx";
import { set } from "date-fns";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";

const StyledToggleButton = styled(ToggleButton)({
  textTransform: "none",
  border: "none",
  padding: " 1rem 1.5rem",
  ":hover": {
    backgroundColor: "rgba(26, 27, 34, 0.08)",
    borderRadius: "6.25rem",
  },
  "&.Mui-selected": {
    backgroundColor: "#C7D1FF",
    borderRadius: "6.25rem",
    color: "#444653",
    "&:hover": {
      backgroundColor: "#C7D1FF",
    },
  },
});

const MUI_X_PRODUCTS = [
  {
    id: "site1",
    label: "Site 1",
    totalMinerCount: 100,
    children: [],
  },
  {
    id: "site2",
    label: "Site 2",
    totalMinerCount: 100,
    children: [],
  },
];

export const WhiteListsLeftPanel = React.memo((props) => {
  const [view, setView] = React.useState("url");
  const history = useHistory();
  const navigateToUrlPage = () => {
    history.push("/pools/whitelist/url");
  };
  const navigateToUsersPage = () => {
    history.push("/pools/whitelist/users");
  };
  return (
    <Box
      className={"left-side-panel"}
      display={"flex"}
      flexDirection={"column"}
      gap={4}
      height={"auto"}
      paddingLeft={"0.75rem"}
      paddingRight={"0.75rem"}
      minWidth={"13vw"}
      width={"13vw"}
      flexGrow={0.1}
      flexShrink={0}
      borderRight={"1px solid #E5E5E5 !important"}
      sx={{
        transition: "all 0.3s ease-in-out",
      }}
    >
      <Box
        className={"control-button"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <H7TitleLarge> Whitelists</H7TitleLarge>
      </Box>
      <Box
        className={"search-box"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={1}
      >
        <ToggleButtonGroup
          orientation="vertical"
          color="primary"
          exclusive
          onChange={(e, value) => value && setView(value)}
          value={view}
          fullWidth
        >
          <StyledToggleButton
            onClick={navigateToUrlPage}
            value="url"
          >
            URL
          </StyledToggleButton>
          <StyledToggleButton
            onClick={navigateToUsersPage}
            value="users"
          >
            Users
          </StyledToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
});
