import React from "react";
import DemandResponseConfigurationPage from "./DemandResponse";
import {
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";

import { DemandResponseConfigurationContextProvider } from "./context/DemandResponseContextProvider";

const DemandResponsePage = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      mutations: {
        // Default options for all mutations
        retry: 1, // Number of retry attempts
        onSuccess: (data) => {
          console.log("Mutation succeeded:", data);
        },
        onError: (error) => {
          console.error("Mutation failed:", error);
        },
      },
    },
  });
  return (
    <DemandResponseConfigurationContextProvider>
      <QueryClientProvider client={queryClient}>
        <DemandResponseConfigurationPage />
      </QueryClientProvider>
    </DemandResponseConfigurationContextProvider>
  );
};

export default DemandResponsePage;
