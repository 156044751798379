import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import {
  Box,
  IconButton,
  ToggleButton,
  ButtonGroup,
  ToggleButtonGroup,
} from "@mui/material";

export const SecondaryButton = styled(Button)({
  color: "#2D55C9",
  borderRadius: "2rem",
  paddingLeft: "0.8rem",
  paddingRight: "0.8rem",
  fontSize: "14px",
  fontWeight: 500,
  minWidth: "5rem",
  height: "2.5rem",
  textTransform: "none",
  ":hover": {
    backgroundColor: "rgba(103, 80, 164, 0.08)",
  },
});

export const PrimaryButton = styled(Button)({
  // backgroundColor: "primary.dark !important",
  backgroundColor: "#2D55C9",
  padding: "0.625rem 1.5rem 0.625rem 1rem",
  "&:hover": {
    backgroundColor: "#2D55C9", // This sets the hover background color to the same as the normal background color
  },
  "&:disabled": {
    backgroundColor: "rgba(26, 27, 34, 0.12)",
    color: "rgba(68, 70, 83, 1)",
    opacity: 0.38,
  },
  borderRadius: "2rem",
  fontSize: "14px",
  fontWeight: 500,
  color: "#FFFFFF",
  minWidth: "5rem",
  height: "2.5rem",
  textTransform: "none",
});

export const StyledButton = styled(Box)({
  minWidth: 84,
  maxWidth: "19rem",
  height: 32,
  padding: "0.375rem 0.5rem 0.375rem 0.75rem",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 8,
  display: "inline-flex",
  backgroundColor: "#C7D1FF",
  borderRadius: 8,
  "&:hover": {
    cursor: "pointer",
  },
});

export const StyledIconButton = styled(IconButton)({
  "&:hover": {
    backgroundColor: "transparent",
  },
});

export const StyledToggleButton = styled(ToggleButton)({
  padding: " 0.375rem 1rem",
  textTransform: "none",
  height: "2rem",
  borderRadius: "0.5rem",
  border: "1px solid #747685",
  color: "#444653",
  fontSize: "0.875rem",
  fontWeight: 500,
  lineHeight: "1.25rem",
  letterSpacing: ".00625rem",

  "&.Mui-selected": {
    display: "flex",
    gap: "0.5rem",
    backgroundColor: "#C7D1FF !important",
    padding: "0.375rem 1rem 0.375rem 0.5rem",
    border: "none",
    alignItems: "center",
  },
});

export const StyledToggleButtonGroup = styled(
  ToggleButtonGroup
)({
  "& .MuiToggleButton-root": {
    border: "1px solid #74777f !important",
  },
  "&.MuiToggleButtonGroup-grouped:not(:first-child):not(:last-child) ":
    {
      borderRadius: 0,
    },
  "& .MuiToggleButtonGroup-grouped:first-child": {
    borderRadius: "50px",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  "& .MuiToggleButtonGroup-grouped:last-child": {
    borderRadius: "50px",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
});

export const OutlinedButton = styled(Button)({
  textTransform: "none",
  borderRadius: "6.25rem",
  border: "1px solid #747685",
  color: "#2D55C9",
  textAlign: "center",
  padding: "0.625rem 1.5rem",
  /* material-theme/label/large */
  fontFamily: "Roboto",
  fontSize: "0.875rem",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "1.25rem" /* 142.857% */,
  letterSpacing: "0.00625rem",
  ":hover": {
    backgroundColor: "rgba(45, 85, 201, 0.08)",
  },
});

export const StyledButtonGroup = styled(ButtonGroup)`
  & .MuiButton-root {
    padding: 0.5em 1em;
    font-size: 1em;
    display: flex;
    align-items: center;
    border: 1px solid #74777f !important;
    //color: #f3f2fd;
  }
  &.MuiButtonGroup-grouped:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  & .MuiButtonGroup-grouped:first-child {
    border-radius: 50px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  & .MuiButtonGroup-grouped:last-child {
    border-radius: 50px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  & .MuiButton-startIcon {
    margin-right: 0.5em;
  }

  & .MuiButton-root:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;
