import React from "react";
import { Box, IconButton, styled } from "@mui/material";

import KeyboardDoubleArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftOutlined";
import TableChartIcon from "@mui/icons-material/TableChartOutlined";
import { H7TitleLarge } from "../../../components/StyledComponents/Typography/Typography.tsx";
import { StyledIconButton } from "../../../components/StyledComponents/Buttons/AuradineButtons";
import { ManageColumns } from "./ManageColumns/ManageColumns";
import MinerFilters from "./MinerFilters/MinerFilters.js";

export const MinerTableHeader = React.memo((props) => {
  const {
    setRightSidePanelOpen,
    rightSidePanelOpen,
    gridRef,
  } = props;
  console.log("rightSidePanelOpen", rightSidePanelOpen);
  return (
    <Box
      className={"table-container-header"}
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <H7TitleLarge> All miners</H7TitleLarge>
      <Box
        display={"flex"}
        flexDirection={"row"}
        gap={2}
        alignItems={"center"}
      >
        <MinerFilters />
        <ManageColumns />
        <StyledIconButton
          onClick={() =>
            setRightSidePanelOpen((prev) => {
              if (!prev === false && gridRef?.current) {
                gridRef.current = null;
              }
              return !prev;
            })
          }
        >
          <KeyboardDoubleArrowLeftOutlinedIcon
            fontSize={"medium"}
            sx={{
              color: "#000000",
              transform: rightSidePanelOpen
                ? "rotate(0deg)"
                : "rotate(180deg)",
            }}
          />
        </StyledIconButton>
      </Box>
    </Box>
  );
});
