import React, {
  createContext,
  useMemo,
  useState,
} from "react";

export const PoolsPageContext = createContext();

const poolConfigurationMockData = [
  {
    id: "site1",
    label: "Site 1",
    pools: [
      {
        url: "url1",
        username: "username1",
        password: "password1",
        workIdentifier: "workIdentifier1",
      },
      {
        url: "url2",
        username: "username2",
        password: "password2",
        workIdentifier: "workIdentifier2",
      },
      {
        url: "url3",
        username: "username3",
        password: "password3",
        workIdentifier: "workIdentifier3",
      },
    ],
  },
  {
    id: "site2",
    label: "Site 2",
    pools: [],
  },
];

export const PoolsPageContextProvider = (props) => {
  const [tableGroup, setTableGroup] = useState({});
  const [detailGroup, setDetailGroup] = useState({});
  const [treeOpen, setTreeOpen] = React.useState(false);
  const [siteSelected, setSiteSelected] = useState(false);
  const [siteSelectedName, setSiteSelectedName] =
    useState("");
  const [groupSelectedName, setGroupSelectedName] =
    useState("");
  const [openAddConfigModal, setOpenAddConfigModal] =
    useState(false);
  const [mockData, setMockData] = React.useState([
    ...poolConfigurationMockData,
  ]);
  const [poolDataToEdit, setPoolDataToEdit] =
    React.useState({});

  const [editModeOn, setEditModeOn] = React.useState(false);
  const [deletionDialogMessage, setDeletionDialogMessage] =
    useState("");
  const [deletionDialogOpen, setDeletionDialogOpen] =
    useState(false);
  const [
    deleteDialogHeaderMessage,
    setDeleteDialogHeaderMessage,
  ] = useState("");

  const contextValue = useMemo(() => {
    return {
      tableGroup,
      setTableGroup,
      detailGroup,
      setDetailGroup,
      treeOpen,
      setTreeOpen,
      siteSelected,
      setSiteSelected,
      siteSelectedName,
      setSiteSelectedName,
      groupSelectedName,
      setGroupSelectedName,
      openAddConfigModal,
      setOpenAddConfigModal,
      mockData,
      setMockData,
      poolDataToEdit,
      setPoolDataToEdit,
      editModeOn,
      setEditModeOn,
      deleteDialogHeaderMessage,
      setDeleteDialogHeaderMessage,
      deletionDialogMessage,
      setDeletionDialogMessage,
      deletionDialogOpen,
      setDeletionDialogOpen,
    };
  }, [
    tableGroup,
    detailGroup,
    treeOpen,
    siteSelected,
    siteSelectedName,
    groupSelectedName,
    openAddConfigModal,
    mockData,
    poolDataToEdit,
    setPoolDataToEdit,
    setMockData,
    setTableGroup,
    setDetailGroup,
    setTreeOpen,
    setSiteSelected,
    setSiteSelectedName,
    setGroupSelectedName,
    setOpenAddConfigModal,
    editModeOn,
    setEditModeOn,
    deleteDialogHeaderMessage,
    setDeleteDialogHeaderMessage,
    deletionDialogMessage,
    setDeletionDialogMessage,
    deletionDialogOpen,
    setDeletionDialogOpen,
  ]);

  return (
    <PoolsPageContext.Provider value={contextValue}>
      {props.children}
    </PoolsPageContext.Provider>
  );
};
