import React, { useCallback, useState } from "react";
import {
  Popover,
  Button,
  Typography,
  Box,
  ClickAwayListener,
} from "@mui/material";
import useDemandResponseContext from "../../context/useDemandResponseContext";
import StyledPopover from "../../../components/StyledComponents/Popover/Popover";
import {
  BodyLarge,
  H7TitleLarge,
  H9TitleSmall,
} from "../../../components/StyledComponents/Typography/Typography.tsx";
import { StyledIconButton } from "../../../components/StyledComponents/Buttons/AuradineButtons";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { format, set } from "date-fns";
import dayjs from "dayjs";
import useAppContextProvider from "../../../AppContext/useAppContextProvider.js";

const EventPopover = () => {
  const {
    eventModalAnchor,
    setEventModalAnchor,
    eventModalData,
    setEventModalData,
    setOpenPowerCurtailOperationModal,
    setEditModeOn,
    calendarEvents,
    setCalendarEvents,
  } = useDemandResponseContext();
  const { setToastMessage, setToastOpen } =
    useAppContextProvider();

  const open = Boolean(eventModalAnchor);
  const id = open ? "simple-popover" : undefined;
  const handleClose = () => {
    setEventModalAnchor(null);
    setEventModalData([]);
  };
  const handleEdit = () => {
    setEventModalAnchor(null);
    setEditModeOn(true);
    setOpenPowerCurtailOperationModal(true);
  };

  const handleDelete = () => {
    const updatedEvents = calendarEvents.filter(
      (event) => event.id !== eventModalData.id
    );
    setCalendarEvents(updatedEvents);
    setEventModalAnchor(null);
    setEventModalData([]);
    setToastMessage(
      `Event ${eventModalData?.id} deleted successfully`
    );
    setToastOpen(true);
  };

  const returnWhenData = useCallback(
    (eventModalData) => {
      let dateFormattedString = "";
      if (eventModalData.length === 0)
        return dateFormattedString;
      else {
        const date = new Date(eventModalData?.start);
        const formattedDate = format(
          date,
          "EEEE, MMMM d, yyyy"
        );
        const startTime = format(
          eventModalData?.start,
          "h:mm a"
        );
        const endTime = format(
          eventModalData?.end,
          "h:mm a"
        );
        dateFormattedString = `${formattedDate}, ${startTime} - ${endTime}`;
      }
      return dateFormattedString;
    },
    [eventModalData]
  );

  console.log("eventModalData", eventModalData);
  const whenData = returnWhenData(eventModalData);

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <>
          <StyledPopover
            id={id}
            open={open}
            anchorEl={eventModalAnchor}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            PaperProps={{
              style: { minWidth: "43rem" },
            }}
          >
            <Box
              display={"grid"}
              gridTemplateRows={"auto auto"}
              className={"event-popover-content"}
              rowGap={6}
            >
              <Box
                display={"grid"}
                gridTemplateColumns={"0.75fr 0.25fr"}
                gap={3}
                className={"event-popover-header"}
              >
                <Box
                  display={"grid"}
                  gridTemplateColumns={"0.05fr 0.95fr"}
                  gap={2}
                  className={"event-popover-header-left"}
                  justifyContent={"start"}
                  alignItems={"center"}
                >
                  <Box
                    style={{
                      width: "1rem",
                      height: "1rem",
                      borderRadius: "50%",
                      backgroundColor:
                        eventModalData?.color,
                    }}
                  ></Box>
                  <H7TitleLarge>
                    {eventModalData?.id}
                  </H7TitleLarge>
                </Box>
                <Box
                  display={"grid"}
                  gridTemplateColumns={"0.75fr 0.25fr"}
                  gap={4}
                >
                  <Box
                    display={"grid"}
                    gridTemplateColumns={"1fr 1fr"}
                    gap={2}
                    justifySelf={"stretch"}
                  >
                    <StyledIconButton
                      disabled={
                        eventModalData?.start < dayjs()
                      }
                      onClick={handleEdit}
                    >
                      <EditRoundedIcon />
                    </StyledIconButton>
                    <StyledIconButton
                      onClick={handleDelete}
                      disabled={
                        eventModalData?.start < dayjs()
                      }
                    >
                      <span className="material-symbols-outlined">
                        delete
                      </span>
                    </StyledIconButton>
                  </Box>
                  <StyledIconButton onClick={handleClose}>
                    <CloseRoundedIcon />
                  </StyledIconButton>
                </Box>
              </Box>
              <Box
                className={"event-popover-body"}
                display={"grid"}
                gridAutoRows={"auto"}
                rowGap={4}
              >
                <Box
                  display={"grid"}
                  gridTemplateColumns={"0.2fr 0.8fr"}
                  className="when-data"
                >
                  <H9TitleSmall>When</H9TitleSmall>
                  <BodyLarge>{whenData}</BodyLarge>
                </Box>
                <Box
                  display={"grid"}
                  gridTemplateColumns={"0.2fr 0.8fr"}
                  className="power-data"
                >
                  <H9TitleSmall>Power</H9TitleSmall>
                  <BodyLarge>
                    {eventModalData?.power} MW
                  </BodyLarge>
                </Box>
                <Box
                  display={"grid"}
                  gridTemplateColumns={"0.2fr 0.8fr"}
                  className="source-data"
                >
                  <H9TitleSmall>Source</H9TitleSmall>
                  <BodyLarge>
                    {eventModalData?.source}
                  </BodyLarge>
                </Box>
              </Box>
            </Box>
          </StyledPopover>
        </>
      </ClickAwayListener>
    </>
  );
};

export default EventPopover;
