import React from "react";
import { Snackbar } from "@mui/material";
import useAppContextProvider from "../../AppContext/useAppContextProvider";
import { set } from "lodash";
import { StyledIconButton } from "../StyledComponents/Buttons/AuradineButtons";
import CloseIcon from "@mui/icons-material/Close";

/**
 * Toast Component
 *
 * This component displays a toast notification using Material-UI's Snackbar component.
 * It utilizes the application context to manage the state of the toast notification.
 *
 * Dependencies:
 * - React
 * - @mui/material (Snackbar)
 * - useAppContextProvider (custom hook for accessing application context)
 * - lodash (set function)
 * - StyledIconButton (custom styled button component)
 * - CloseIcon (Material-UI icon for the close button)
 *
 * Context:
 * - toastOpen: Boolean indicating whether the toast is open or not.
 * - toastMessage: String containing the message to be displayed in the toast.
 * - setToastOpen: Function to set the state of toastOpen.
 * - setToastMessage: Function to set the state of toastMessage.
 *
 * Functions:
 * - handleClose: Event handler for closing the toast. It prevents closing the toast if the reason is "clickaway".
 *
 * UI Elements:
 * - Snackbar: The main component for displaying the toast notification.
 * - StyledIconButton: A custom styled button used to close the toast.
 * - CloseIcon: An icon displayed inside the StyledIconButton.
 *
 * Example usage:
 * <Toast />
 *
 * This component should be used within a context provider that supplies the necessary context values.
 */
export default function Toast() {
  const {
    toastOpen,
    toastMessage,
    setToastOpen,
    setToastMessage,
  } = useAppContextProvider();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOpen(false);
    setToastMessage("");
  };

  const action = (
    <React.Fragment>
      <StyledIconButton
        size="small"
        aria-label="close"
        onClick={() => {
          setToastOpen(false);
          setToastMessage("");
        }}
      >
        <CloseIcon
          fontSize="small"
          style={{ color: "rgba(245, 239, 247, 1)" }}
        />
      </StyledIconButton>
    </React.Fragment>
  );
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={toastOpen}
      autoHideDuration={7500}
      onClose={handleClose}
      message={toastMessage}
      action={action}
    />
  );
}
