/**
 * This component represents a dialog for adding a price configuration.
 * It allows the user to select an Independent System Operator, choose a power purchase agreement type,
 * select a prize zone, and review the form before submission.
 *
 * @component
 * @example
 * return (
 *   <AddPriceConfigDialog />
 * )
 */
import React, { useEffect } from "react";
import {
  StyledIconButton,
  PrimaryButton,
  SecondaryButton,
} from "../../../components/StyledComponents/Buttons/AuradineButtons.js";

import { useState } from "react";
import { StyledDialog } from "../../../components/StyledComponents/Dialog/Dialog.js";
import {
  BodyLarge,
  H6HeadlineSmall,
  H8TitleMedium,
  H9TitleSmall,
} from "../../../components/StyledComponents/Typography/Typography.tsx";
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import useAppContextProvider from "../../../AppContext/useAppContextProvider.js";
import usePriceConfigurationContext from "../../context/usePriceConfigurationContext.js";
import PlaceHolderButton from "../../../components/PlaceHolderButton/PlaceHolderButton.js";
import StyledLinearProgress from "../../../components/StyledComponents/Progress/Progress.js";

const PriceConfigurationFormHeader = (props) => {
  const { setFormData } = props;
  const {
    setOpenAddPriceConfigModal,
    setActiveStep,
    setTotalSteps,
    editModeOn,
    siteConfigurationDataToEdit,
    setSiteConfigurationDataToEdit,
    setEditConfigModeEnabled,
  } = usePriceConfigurationContext();
  console.log(siteConfigurationDataToEdit);
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"space-between"}
      flexGrow={1}
      padding={"1rem 0"}
    >
      <H6HeadlineSmall>
        {!editModeOn
          ? `Create a New Price Configuration`
          : `Edit Price Configuration `}
      </H6HeadlineSmall>
      <StyledIconButton
        onClick={() => {
          setOpenAddPriceConfigModal(false);
          setFormData({});
          setActiveStep(0);
          setTotalSteps(4);
          setSiteConfigurationDataToEdit(null);
          setEditConfigModeEnabled(false);
        }}
      >
        <CloseIcon />
      </StyledIconButton>
    </Box>
  );
};

const SystemOperatorForm = ({
  onNext,
  formData,
  setFormData,
}) => {
  const {
    priceConfig,
    setTotalSteps,
    siteConfigurationDataToEdit,
  } = usePriceConfigurationContext();
  const { control, handleSubmit, watch, setValue, reset } =
    useForm({
      defaultValues: {
        independentSystemOperator:
          priceConfig?.independentSystemOperator ||
          formData?.independentSystemOperator ||
          "",
      },
    });

  useEffect(() => {
    if (siteConfigurationDataToEdit) {
      setValue(
        "independentSystemOperator",
        siteConfigurationDataToEdit?.independentSystemOperator
      );
    }
  }, [siteConfigurationDataToEdit]);

  const selectedOption = watch("independentSystemOperator");

  const onSubmit = (data) => {
    const finalData =
      selectedOption?.toLowerCase() === "ercot"
        ? { ...data }
        : { powerPurchaseAgreement: "hedge", ...data };
    const totalSteps =
      selectedOption?.toLowerCase() === "none" ? 3 : 4;
    setTotalSteps(totalSteps);

    onNext(finalData);
  };
  return (
    <>
      <PriceConfigurationFormHeader
        setFormData={setFormData}
      />
      <DialogContent sx={{ padding: 0 }}>
        <Box
          display="flex"
          flexDirection={"column"}
          gap={4}
          paddingTop={"2.5rem"}
          paddingBottom={"2.5rem"}
        >
          <H8TitleMedium>
            {" "}
            Select an Independent System Operator
          </H8TitleMedium>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl component="fieldset">
              <Controller
                name="independentSystemOperator"
                control={control}
                rules={{
                  required: "Please select an option",
                }}
                render={({
                  field,
                  fieldState: { error },
                }) => (
                  <>
                    <RadioGroup {...field}>
                      <FormControlLabel
                        value="ERCOT"
                        control={<Radio />}
                        label="ERCOT"
                      />
                      <FormControlLabel
                        value="none"
                        control={<Radio />}
                        label="None"
                      />
                    </RadioGroup>
                  </>
                )}
              />
            </FormControl>
          </form>
        </Box>
      </DialogContent>
      <DialogActions>
        <PrimaryButton
          disabled={!selectedOption}
          onClick={handleSubmit(onSubmit)}
        >
          Next
        </PrimaryButton>
      </DialogActions>
    </>
  );
};

const PowerPurchaseAgreementForm = ({
  formData,
  onNext,
  onBack,
  setFormData,
}) => {
  const { priceConfig, siteConfigurationDataToEdit } =
    usePriceConfigurationContext();
  const { control, handleSubmit, watch, setValue, reset } =
    useForm({
      defaultValues: {
        powerPurchaseAgreement:
          priceConfig?.powerPurchaseAgreement ||
          formData?.powerPurchaseAgreement ||
          "",
      },
    });

  useEffect(() => {
    if (siteConfigurationDataToEdit) {
      reset({
        powerPurchaseAgreement:
          siteConfigurationDataToEdit?.powerPurchaseAgreement,
      });
    }
  }, [siteConfigurationDataToEdit]);
  const onSubmit = (data) => {
    onNext(data);
  };

  const selectedOption = watch("powerPurchaseAgreement");
  return (
    <>
      <PriceConfigurationFormHeader
        setFormData={setFormData}
      />
      <DialogContent sx={{ padding: 0 }}>
        <Box
          display="flex"
          flexDirection={"column"}
          gap={4}
          paddingTop={"2.5rem"}
          paddingBottom={"2.5rem"}
        >
          <H8TitleMedium>
            {" "}
            Choose your power purchase agreement type.
          </H8TitleMedium>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl component="fieldset">
              <Controller
                name="powerPurchaseAgreement"
                control={control}
                rules={{
                  required: "Please select an option",
                }}
                render={({
                  field,
                  fieldState: { error },
                }) => (
                  <>
                    <RadioGroup {...field}>
                      <FormControlLabel
                        value="Index"
                        control={<Radio />}
                        label="Index"
                      />
                      <FormControlLabel
                        value="Hedge"
                        control={<Radio />}
                        label="Hedge"
                      />
                    </RadioGroup>
                  </>
                )}
              />
            </FormControl>
          </form>
        </Box>
      </DialogContent>

      <DialogActions>
        <SecondaryButton onClick={onBack}>
          Back
        </SecondaryButton>
        <PrimaryButton
          disabled={!selectedOption}
          onClick={handleSubmit(onSubmit)}
        >
          Next
        </PrimaryButton>
      </DialogActions>
    </>
  );
};

const PrizeZoneForm = ({
  formData,
  onNext,
  onBack,
  setFormData,
}) => {
  const { priceConfig, siteConfigurationDataToEdit } =
    usePriceConfigurationContext();

  const { control, handleSubmit, watch, setValue, reset } =
    useForm({
      defaultValues: {
        prizeZone:
          priceConfig?.prizeZone ||
          formData?.prizeZone ||
          "",
        costOfElectricity:
          priceConfig?.costOfElectricity ||
          formData?.costOfElectricity,
      },
    });

  useEffect(() => {
    if (siteConfigurationDataToEdit) {
      setValue(
        "prizeZone",
        siteConfigurationDataToEdit?.prizeZone
      );
      setValue(
        "costOfElectricity",
        siteConfigurationDataToEdit?.costOfElectricity
      );
    }
  }, [siteConfigurationDataToEdit]);
  const generateRandomNumber = () => {
    return Math.round(
      Math.random() * (70 - 60) + 60
    ).toFixed(2);
  };
  const { powerPurchaseAgreement } = formData;
  const onSubmit = (data) => {
    const electricityCost =
      powerPurchaseAgreement?.toLowerCase() === "index"
        ? generateRandomNumber()
        : data?.costOfElectricity;
    const finalData = {
      ...data,
      costOfElectricity: electricityCost,
    };

    onNext(finalData);
  };

  const selectedOption =
    watch("prizeZone") || watch("costOfElectricity");
  return (
    <>
      <PriceConfigurationFormHeader
        setFormData={setFormData}
      />
      <DialogContent sx={{ padding: 0 }}>
        <Box
          display="flex"
          flexDirection={"column"}
          gap={4}
          paddingTop={"2.5rem"}
          paddingBottom={"2.5rem"}
        >
          {powerPurchaseAgreement?.toLowerCase() ===
            "index" && (
            <H8TitleMedium>
              {" "}
              Select your prize zone.
            </H8TitleMedium>
          )}
          {powerPurchaseAgreement.toLowerCase() ===
            "hedge" && (
            <H8TitleMedium>
              Enter your cost of electricity (Per MW Per
              Hour)
            </H8TitleMedium>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            {powerPurchaseAgreement?.toLowerCase() ===
              "index" && (
              <FormControl fullWidth margin="none">
                <InputLabel>Price zone</InputLabel>
                <Controller
                  name="prizeZone"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      sx={{ minHeight: "3.5rem" }}
                      variant="outlined"
                      label="Price Zone"
                      fullWidth
                      placeholder="Price Zone"
                      inputProps={{
                        sx: {
                          padding: "1rem 0.5rem",
                        },
                      }}
                      onChange={(event) =>
                        field.onChange(event.target.value)
                      }
                    >
                      <MenuItem value={"HB_HOUSTON"}>
                        HB_HOUSTON
                      </MenuItem>
                      <MenuItem value={"HB_NORTH"}>
                        HB_NORTH
                      </MenuItem>
                      <MenuItem value={"HB_PAN"}>
                        HB_PAN
                      </MenuItem>
                      <MenuItem value={"HB_SOUTH"}>
                        HB_SOUTH
                      </MenuItem>
                      <MenuItem value={"HB_WEST"}>
                        HB_WEST
                      </MenuItem>
                      <MenuItem value={"LZ_AEN"}>
                        LZ_AEN
                      </MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            )}
            {powerPurchaseAgreement?.toLowerCase() ===
              "hedge" && (
              <FormControl fullWidth margin="none">
                {/*<InputLabel>Status</InputLabel>*/}
                <InputLabel htmlFor="cost-of-electricity">
                  In USD
                </InputLabel>
                <Controller
                  name="costOfElectricity"
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <OutlinedInput
                      {...field}
                      label="In USD"
                      id="cost-of-electricity"
                      style={{
                        flex: 1,
                        margin: 0,
                        minHeight: "2.5rem",
                        // padding: "16.5px 14px",
                      }}
                    />
                  )}
                />
              </FormControl>
            )}
          </form>
        </Box>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={onBack}>
          Back
        </SecondaryButton>
        <PrimaryButton
          disabled={!selectedOption}
          onClick={handleSubmit(onSubmit)}
        >
          next
        </PrimaryButton>
      </DialogActions>
    </>
  );
};

const ReviewForm = ({
  formData,
  onSubmit: onOverallSubmit,
  onBack,
  setFormData,
}) => {
  const { priceConfig, siteConfigurationDataToEdit } =
    usePriceConfigurationContext();
  const { control, handleSubmit, watch, setValue, reset } =
    useForm({
      defaultValues: {
        siteConfig:
          priceConfig?.siteConfig ||
          formData?.siteConfig ||
          [],
        priceConfigName:
          priceConfig?.priceConfigName ||
          formData?.priceConfigName ||
          "",
      },
    });

  useEffect(() => {
    if (siteConfigurationDataToEdit) {
      setValue(
        "siteConfig",
        siteConfigurationDataToEdit?.siteConfig
      );
      setValue(
        "priceConfigName",
        siteConfigurationDataToEdit?.priceConfigName
      );
    }
  }, [siteConfigurationDataToEdit]);

  const selectedOption = watch("siteConfig");
  const priceConfigName = watch("priceConfigName");

  const siteOptions = [
    "site1",
    "site2",
    "site3",
    "site4",
    "site5",
  ];

  const labelFormData = [
    {
      propertyName: "independentSystemOperator",
      value: formData?.independentSystemOperator,
      label: "Independent System Operator",
    },
    {
      propertyName: "powerPurchaseAgreement",
      value: formData?.powerPurchaseAgreement,
      label: "Power Purchase Agreement",
    },
    ...(formData?.powerPurchaseAgreement?.toLowerCase() ===
    "index"
      ? [
          {
            propertyName: "prizeZone",
            value: formData?.prizeZone,
            label: "Price Zone",
          },
        ]
      : []),
    {
      propertyName: "costOfElectricity",
      value: formData?.costOfElectricity,
      label: "Cost of Electricity",
    },
  ];
  console.log("formData", formData);
  console.log("labelFormData", labelFormData);
  const onSubmit = (data) => {
    onOverallSubmit(data);
  };

  const handleSiteConfigDeletion = (optionToDelete) => {
    const newValue = selectedOption.filter(
      (option) => option !== optionToDelete
    );
    setValue("siteConfig", newValue);
  };

  return (
    <>
      <PriceConfigurationFormHeader
        setFormData={setFormData}
      />
      <DialogContent sx={{ padding: 0 }}>
        <Box
          display="flex"
          flexDirection={"column"}
          gap={8}
          paddingTop={"2.5rem"}
          paddingBottom={"2.5rem"}
        >
          <H8TitleMedium>
            Review the following details for your new price
            configuration and select the sites you want to
            assign it to
          </H8TitleMedium>
          <Box
            display={"grid"}
            gridTemplateColumns={"1fr 2fr"}
            padding={"0.5rem"}
            borderRadius={"0.5rem"}
            sx={{ backgroundColor: "#E8E7F1" }}
            gap={6}
          >
            {labelFormData.map((item, index) => (
              <>
                <H9TitleSmall>{item?.label}</H9TitleSmall>
                <BodyLarge>{item?.value}</BodyLarge>
              </>
            ))}
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={8}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={4}
                className="priceConfigName"
              >
                <H8TitleMedium>
                  Enter a price configuration name
                </H8TitleMedium>
                <Box
                  display="flex"
                  flexDirection="row"
                  flexGrow={1}
                >
                  <FormControl fullWidth margin="none">
                    <InputLabel htmlFor="site-configuration-name">
                      Enter site configuration name
                    </InputLabel>
                    <Controller
                      name="priceConfigName"
                      control={control}
                      defaultValue={null}
                      render={({ field }) => (
                        <OutlinedInput
                          {...field}
                          id="site-configuration-name"
                          variant="outlined"
                          label="Enter site configuration name"
                          style={{
                            flex: 1,
                            margin: 0,
                            minHeight: "2.5rem",
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={4}
                className="sitesContainer"
              >
                <H8TitleMedium>
                  Select sites to assign price configuration
                </H8TitleMedium>
                <Box
                  display="flex"
                  flexDirection="row"
                  flexGrow={1}
                >
                  <FormControl fullWidth margin="none">
                    {/* <InputLabel htmlFor="">Status</InputLabel> */}
                    <Controller
                      name="siteConfig"
                      control={control}
                      defaultValue={null}
                      render={({
                        field: { onChange, value, ref },
                      }) => (
                        <Autocomplete
                          fullWidth
                          multiple
                          disableCloseOnSelect
                          onChange={(_, data) =>
                            onChange(data)
                          }
                          value={value || []}
                          clearIcon={null}
                          options={siteOptions} // Replace with your options
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Site Name"
                              variant="outlined"
                              inputRef={ref}
                              sx={{ minHeight: "2.5rem" }}
                            />
                          )}
                          renderTags={() => null}
                        />
                      )}
                    />
                  </FormControl>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  gap={4}
                  maxWidth={"62.5rem"}
                  flexWrap={"wrap"}
                >
                  {selectedOption?.map((site, index) => (
                    <PlaceHolderButton
                      key={index}
                      onClose={() =>
                        handleSiteConfigDeletion(site)
                      }
                      text={site}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          </form>
        </Box>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={onBack}>
          Back
        </SecondaryButton>
        <PrimaryButton
          disabled={!selectedOption}
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </PrimaryButton>
      </DialogActions>
    </>
  );
};

const AddPriceConfigDialogModal = () => {
  const {
    openAddPriceConfigModal,
    setOpenAddPriceConfigModal,
    mockData,
    setMockData,
    activeStep,
    setActiveStep,
    totalSteps,
    setTotalSteps,
    initialFormDataForAddingPriceConfig,
    editConfigModeEnabled,
  } = usePriceConfigurationContext();

  const [configFormData, setConfigFormData] = useState(
    initialFormDataForAddingPriceConfig
  );
  const { setToastMessage, setToastOpen } =
    useAppContextProvider();

  const onMoveForward = (data) => {
    console.log("onMoveForward data", {
      ...configFormData,
      ...data,
    });
    setConfigFormData({ ...configFormData, ...data });
    setActiveStep(activeStep + 1);
  };

  const onSubmit = (data) => {
    setMockData([
      ...mockData,
      { ...configFormData, ...data },
    ]);
    setToastMessage(
      editConfigModeEnabled
        ? "Price configuration edited successfully"
        : "Price configuration added successfully"
    );
    setToastOpen(true);
    setOpenAddPriceConfigModal(false);
    setConfigFormData({});
    setActiveStep(0);
    setTotalSteps(4);
  };

  const onMoveBackward = () => {
    setActiveStep(activeStep - 1);
  };

  const independentSystemOperator =
    configFormData?.independentSystemOperator;

  const progress = (activeStep / totalSteps) * 100;

  return (
    <StyledDialog
      maxWidth={"lg"}
      fullWidth
      open={openAddPriceConfigModal}
      hideBackdrop
    >
      <StyledLinearProgress
        variant="determinate"
        value={progress}
        sx={{ marginBottom: "1rem" }}
      />
      {activeStep === 0 && (
        <SystemOperatorForm
          formData={configFormData}
          onNext={onMoveForward}
          setFormData={setConfigFormData}
        />
      )}
      {independentSystemOperator?.toLowerCase() ===
      "ercot" ? (
        <>
          {activeStep === 1 && (
            <PowerPurchaseAgreementForm
              formData={configFormData}
              onNext={onMoveForward}
              onBack={onMoveBackward}
              setFormData={setConfigFormData}
            />
          )}
          {activeStep === 2 && (
            <PrizeZoneForm
              formData={configFormData}
              onNext={onMoveForward}
              onBack={onMoveBackward}
              setFormData={setConfigFormData}
            />
          )}
          {activeStep === 3 && (
            <ReviewForm
              formData={configFormData}
              onSubmit={onSubmit}
              onBack={onMoveBackward}
              setFormData={setConfigFormData}
            />
          )}
        </>
      ) : (
        <>
          {activeStep === 1 && (
            <PrizeZoneForm
              formData={configFormData}
              onNext={onMoveForward}
              onBack={onMoveBackward}
              setFormData={setConfigFormData}
            />
          )}
          {activeStep === 2 && (
            <ReviewForm
              formData={configFormData}
              onSubmit={onSubmit}
              onBack={onMoveBackward}
              setFormData={setConfigFormData}
            />
          )}
        </>
      )}
    </StyledDialog>
  );
};

const AddPriceConfigDialog = (props) => {
  return (
    <>
      <AddPriceConfigDialogModal />
    </>
  );
};

export default AddPriceConfigDialog;
