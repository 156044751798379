import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {
  ClickAwayListener,
  createTheme,
  ThemeProvider,
  Typography,
  ButtonGroup,
  Button,
  Popover,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Popper } from "@mui/material";
import styled from "@emotion/styled";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import {
  SecondaryButton,
  StyledButtonGroup,
} from "../../StyledComponents/Buttons/AuradineButtons";
import PrivacyAndTermsDialog from "../../privacyAndTermsDialog";

const StyledPopoverContent = styled(Box)`
  display: flex;
  padding: 1rem 1.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1rem;
  background: #f3f2fd;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
`;

const ProfileCardNameTypography = styled(Typography)({
  color: "#44474E",
  textAlign: "center",
  fontFamily: "Roboto",
  fontSize: "1.375rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "1.75rem",
});

const ProfileCardEmailTypography = styled(Typography)({
  color: "#44474E",
  textAlign: "center",
  fontFamily: "Roboto",
  position: "relative",
  fontSize: "0.875rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "1.25rem",
});

const StyledPopover = styled(Popover)`
  & .MuiPaper-root {
    border-radius: 1rem; // Change this to the border radius you want
  }
`;

const ProfileCardButtonGroupConfig = (
  navigate,
  logout,
  handleClose
) => {
  return [
    {
      buttonText: "Edit account",
      onClickFunc: () => {
        navigate.push("/user");
        handleClose();
      },
      startIcon: <EditOutlinedIcon />,
    },
    {
      buttonText: "Sign out",
      onClickFunc: () => {
        logout({ returnTo: window.location.origin });
        handleClose();
      },
      startIcon: <LogoutOutlinedIcon />,
    },
  ];
};

const ProfileCard = (props) => {
  const { open, handleClose, anchorRef } = props;
  const [privacyDialogOpen, setPrivacyDialogOpen] =
    React.useState(false);
  const [termsDialogOpen, setTermsDialogOpen] =
    React.useState(false);

  const { logout, user } = useAuth0();
  const navigate = useHistory();
  const buttonConfig = ProfileCardButtonGroupConfig(
    navigate,
    logout,
    handleClose
  );

  return (
    <>
      <StyledPopover
        sx={{ zIndex: 9999, borderRadius: "1rem" }}
        open={open}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <StyledPopoverContent>
            <Box display={"flex"} flexDirection={"column"}>
              <ProfileCardNameTypography>
                {user?.nickname}
              </ProfileCardNameTypography>
              <ProfileCardEmailTypography>
                {user?.email}
              </ProfileCardEmailTypography>
            </Box>
            <Box
              pb={"0.5rem"}
              pt={"0.5rem"}
              display={"flex"}
              flexDirection={"column"}
              gap={"1rem"}
            >
              <StyledButtonGroup>
                {buttonConfig?.map((button, index) => (
                  <Button
                    key={index}
                    sx={{
                      textTransform: "none",
                      color: "#44474E",
                    }}
                    onClick={button.onClickFunc}
                    startIcon={button.startIcon}
                  >
                    {button?.buttonText}
                  </Button>
                ))}
              </StyledButtonGroup>
              <Box display={"flex"} flexDirection={"row"}>
                <SecondaryButton
                  onClick={() => setPrivacyDialogOpen(true)}
                >
                  Privacy policy
                </SecondaryButton>
                <SecondaryButton
                  onClick={() => setTermsDialogOpen(true)}
                >
                  Terms of service
                </SecondaryButton>
              </Box>
            </Box>
          </StyledPopoverContent>
        </ClickAwayListener>
      </StyledPopover>
      <PrivacyAndTermsDialog
        name="terms-2022-08"
        buttonTitle="Terms of Service"
        title="Terms of Service"
        page="../../terms.html"
        open={termsDialogOpen}
        setopen={setTermsDialogOpen}
      />
      <PrivacyAndTermsDialog
        name="privacy-2022-08"
        buttonTitle="Privacy Policy"
        title="Privacy Policy"
        page="./privacy.html"
        open={privacyDialogOpen}
        setopen={setPrivacyDialogOpen}
      />
    </>
  );
};

const AvatarMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const anchorRef = React.useRef(null);
  const { user } = useAuth0();
  const [open, setOpen] = React.useState(false);
  const navigate = useHistory();
  const handleClick = (event) => {
    event.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          // paddingRight: "2rem",
        }}
      >
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            ref={anchorRef}
            size="medium"
            aria-controls={
              open ? "account-menu" : undefined
            }
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar
              sx={{
                width: 32,
                height: 32,
                bgcolor: "primary.dark",
              }}
            >
              {user?.name?.charAt(0).toUpperCase()}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>

      <ProfileCard
        open={open}
        handleClose={handleClose}
        anchorRef={anchorRef}
      />
    </React.Fragment>
  );
};

export default AvatarMenu;
