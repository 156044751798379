import React from "react";
import {
  StyledIconButton,
  StyledToggleButton,
  PrimaryButton,
  SecondaryButton,
} from "../../../components/StyledComponents/Buttons/AuradineButtons.js";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterList from "@mui/icons-material/FilterList";
import { useState } from "react";
import {
  StyledDialog,
  StyledDialogTitle,
} from "../../../components/StyledComponents/Dialog/Dialog.js";
import {
  H5HeadlineMedium,
  H6HeadlineSmall,
  H8TitleMedium,
} from "../../../components/StyledComponents/Typography/Typography.tsx";
import {
  DialogContent,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";

const AddUsersToWhiteListPanel = (props) => {
  const { open, setOpen } = props;
  const formRef = React.useRef();

  const { control, handleSubmit, watch, setValue, reset } =
    useForm({
      defaultValues: {
        url: "",
      },
    });

  const onSubmit = (data) => {
    setOpen(false);
  };

  return (
    <StyledDialog maxWidth={"lg"} open={open} hideBackdrop>
      <StyledDialogTitle sx={{ paddingTop: 0 }}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <H5HeadlineMedium style={{ textAlign: "left" }}>
            Add a user to the whitelist
          </H5HeadlineMedium>
          <StyledIconButton
            onClick={() => setOpen(false)}
            style={{ textAlign: "right" }}
          >
            <CloseIcon />
          </StyledIconButton>
        </Box>
      </StyledDialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <form
          ref={formRef}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={8}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={4}
              className="url-details-container"
            >
              <H8TitleMedium>
                Enter the user you want to add to your
                whitelist
              </H8TitleMedium>
              <Box
                display="flex"
                flexDirection="row"
                flexGrow={1}
              >
                <FormControl fullWidth margin="none">
                  <Controller
                    name="user"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        label="User"
                        fullWidth
                        onChange={(event) =>
                          field.onChange(event.target.value)
                        }
                        inputProps={{
                          sx: {
                            padding: "1rem 0.5rem",
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            padding: "0.2rem 0",
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Box>
            </Box>

            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"flex-end"}
              gap={4}
              className="action-button-container"
            >
              <PrimaryButton
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                Add
              </PrimaryButton>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </StyledDialog>
  );
};

const AddUserDialog = (props) => {
  const { open, setOpen } = props;
  return (
    <>
      <AddUsersToWhiteListPanel
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};

export default AddUserDialog;
