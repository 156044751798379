import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { A } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import useDemandResponseContext from "../../context/useDemandResponseContext";

const SmallCalendar = () => {
  const { selectedDate, setSelectedDate } =
    useDemandResponseContext();

  const handleDateChange = (date) => {
    setSelectedDate(date.toDate());
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        views={["day"]}
        onChange={handleDateChange}
        sx={{
          "& .MuiPickersDay-root.Mui-selected": {
            backgroundColor: "#2D55C9",
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default SmallCalendar;
