import { Popover } from "@mui/material";
import styled from "@emotion/styled";

const StyledPopover = styled(Popover)(({ theme }) => ({
  " .MuiPaper-root": {
    borderRadius: "1.5rem",
    padding: "2rem",
    backgroundColor: "#EEEDF7",
  },
}));

export default StyledPopover;
