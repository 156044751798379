import styled from "@emotion/styled";
import { LinearProgress } from "@mui/material";
import React from "react";

const StyledLinearProgress = styled(LinearProgress)(
  ({ theme }) => ({
    height: "0.25rem",
    "&.MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#1a90ff",
    },
    "&.MuiLinearProgress-root": {
      borderRadius: 5,
      backgroundColor: "#d3d3d3", // Track color // Progress color
    },
  })
);

export default StyledLinearProgress;
