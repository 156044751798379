import React from "react";
import { StyledDataGrid } from "../StyledComponents/DataGrid/StyledDataGrid.tsx";
import { LicenseInfo } from "@mui/x-license";
import { LicenseInfoProvider } from "@mui/x-license/Unstable_LicenseInfoProvider/LicenseInfoProvider";
import { env } from "../../env.js";

LicenseInfo.setLicenseKey(env.MUI_LICENSE_KEY);
const DataGrid = (props) => {
  const {
    config,
    height,
    maxHeight,
    columnVisibilityModel,
  } = props;

  //@ts-nocheck @ts-ignore
  return (
    <div
      style={{
        height: height !== undefined ? `${height}` : `70vh`,
        maxHeight: maxHeight ? `${maxHeight}` : "",
        width: "100%",
      }}
    >
      <StyledDataGrid
        sx={{
          borderWidth: "0px !important",
          borderStyle: "none !important",
          borderColor: "none !important",
          "--DataGrid-rowBorderColor": "#C4C5D6 !important",
          "& .MuiDataGrid-columnHeaders": {
            minHeight: "0 !important", // Remove header height
            // backgroundColor: "unset !important",
            borderBottom: "none !important",
          },
          "& .MuiDataGrid-columnHeader": {
            // backgroundColor: "unset !important",
            borderBottom: "none !important",
          },
          "& .MuiDataGrid-detailPanel": {
            backgroundColor: "#E8E7F1 !important",
            borderBottomLeftRadius: "1rem",
            borderBottomRightRadius: "1rem", // Force the removal of background color
          },
        }}
        {...config}
      />
    </div>
  );
};

export default DataGrid;
