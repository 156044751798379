import React, { useState, memo } from "react";
import { Box } from "@mui/material";

import { H7TitleLarge } from "../../../components/StyledComponents/Typography/Typography.tsx";
import {
  StyledIconButton,
  SecondaryButton,
  PrimaryButton,
} from "../../../components/StyledComponents/Buttons/AuradineButtons.js";

import AddIcon from "@mui/icons-material/Add";
import { useHistory, Link } from "react-router-dom";
import AddUserDialog from "../AddUsersToWhiteListDialog/AddUsersToWhiteListDialog.js";

export const WhiteListsUsersHeader = memo((props) => {
  const { data } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const history = useHistory();

  return (
    <Box
      className={"table-container-header"}
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
      >
        <H7TitleLarge>Users</H7TitleLarge>
      </Box>
      <Box display={"flex"} flexDirection={"row"} gap={2}>
        <Box
          className={"action-container"}
          display={"flex"}
          flexDirection={"row"}
          gap={2}
        >
          <>
            <PrimaryButton
              onClick={() => {
                setOpenModal(true);
              }}
            >
              Add <AddIcon />
            </PrimaryButton>
          </>
        </Box>
      </Box>
      <AddUserDialog
        setOpen={setOpenModal}
        open={openModal}
      />
    </Box>
  );
});
